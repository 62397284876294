/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { IntegrationActions, MerlotState } from '@dpa-shared-merlot/store';
import { IntegrationDetailView, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Allow integration view page only if integration type is available in query params
 * @param {ActivatedRouteSnapshot} activatedRouteSnapshot - Holds activated route snapshot
 * @export
 * @returns {boolean}
 */
export function integrationViewGuard(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
  const store = inject(Store<MerlotState>);
  const router = inject(Router);

  const queryParams: Params = activatedRouteSnapshot.queryParams;
  if (!queryParams?.integration) {
    router.navigate([`/${ROUTE_NAMES.INTEGRATIONS.INBOUND_CONNECTORS}`]);
    return false;
  }

  const integrationDetailView: IntegrationDetailView = new IntegrationDetailView({
    ...queryParams,
    modified: parseInt(queryParams.modified, 10),
    status: queryParams.status === 'true',
  });

  if (queryParams?.tags) {
    integrationDetailView.tags = Array.isArray(queryParams.tags) ? queryParams.tags : [queryParams.tags];
  }

  store.dispatch(IntegrationActions.initializeIntegrationView({ integrationDetailView }));
  return true;
}
