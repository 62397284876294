/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { UserPreferenceFeatureControlsSelectors, UserPreferenceTrialInfoSelectors } from '@ws1c/intelligence-core';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access standalone trial only when the guard is enabled
 * @export
 * @returns {Observable<boolean>}
 */
export function standaloneTrialGuard(): Observable<boolean> {
  const store = inject(Store<MerlotState>);
  const router = inject(Router);
  return combineLatest([
    store.select(UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled),
    store.select(UserPreferenceTrialInfoSelectors.isTrialExpired),
  ]).pipe(
    map(([isFeatureEnabled, isTrialExpired]: [boolean, boolean]) => {
      if (isFeatureEnabled && isTrialExpired) {
        router.navigate([`/${ROUTE_NAMES.TRIAL_ENDED}`]);
        return false;
      }
      return true;
    }),
  );
}
