/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access Apteligent only when the guard is enabled
 * @export
 * @returns {Observable<boolean>}
 */
export function appLoadsGuard(): Observable<boolean> {
  const store = inject(Store<MerlotState>);
  const router = inject(Router);
  return store.select(UserPreferenceFeatureControlsSelectors.isApteligentEnabled).pipe(
    map((isFeatureEnabled: boolean) => {
      if (isFeatureEnabled) {
        return true;
      }
      router.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`]);
      return false;
    }),
  );
}
