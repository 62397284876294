/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@ws1c/intelligence-core';

/**
 * Make sure user can access UI only after authenticated
 * @export
 * @returns {Observable<boolean>}
 */
export function acceptEulaGuard(): Observable<boolean> {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isEulaAccepted$.pipe(
    map((isAccepted: boolean) => {
      if (isAccepted) {
        router.navigate(['/']);
      }
      return !isAccepted;
    }),
  );
}
