/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { OrgSelectors } from '@ws1c/intelligence-core';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Allow onboarding page route only if any of the onboarding steps is not complete
 * @export
 * @returns {Observable<boolean>}
 */
export function onboardingPageGuard(): Observable<boolean> {
  const store = inject(Store<MerlotState>);
  const router = inject(Router);
  return store.select(OrgSelectors.isOnboardingComplete).pipe(
    map((isOnboardingComplete: boolean) => {
      if (!isOnboardingComplete) {
        return true;
      }
      router.navigate([`/${ROUTE_NAMES.WORKSPACE.HOME}`]);
      return false;
    }),
  );
}
